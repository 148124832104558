<template>
	<v-container class="profile px-8">
    <div style="max-width: 800px; margin: auto">
      <v-row>
        <h2 class="my-4">User Profile</h2>
      </v-row>
      <v-row>
        <h3 class="mt-5">Display Name</h3>
      </v-row>
      <v-row>
        <v-text-field
            :disabled="saveDisplayNameInProgress"
            v-model="displayName"></v-text-field>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn :loading="saveDisplayNameInProgress"
               color="#0D7542" style="color: #FAF5E3"
               @click="updateDisplayName()">Save</v-btn>
      </v-row>
      <v-row class="mt-6">
        <h3>Email Address</h3>
      </v-row>
      <v-row>
        <span>{{cognito.attributes.email}}</span>
      </v-row>

      <v-row class="mt-6">
        <h3>Account Type</h3>
      </v-row>
      <v-row>
        <span v-if="cognito.attributes['custom:mutableUserType'] === 'guest'">
          Portal Guest (Free Account)
        </span>
        <span v-else-if="cognito.attributes['custom:mutableUserType'] === 'member'">
          Business Employee Account
        </span>
        <span v-if="cognito.attributes['custom:mutableUserType'] === 'admin'">
          Administrator Account for Business: <strong>{{organization.companyName}}</strong>
        </span>
      </v-row>

      <v-row class="mt-6"
          v-if="cognito.attributes['custom:orgName'] || (organization && organization.companyName)">
        <h3 class="fill">Business Name</h3>
        <span v-if="organization && organization.companyName">
          {{organization.companyName}}
        </span>
        <span v-else-if="cognito.attributes['custom:orgName']">
          {{cognito.attributes['custom:orgName']}}
        </span>
      </v-row>
 </div>
  </v-container>
</template>

<script>
	import {VueController} from '../service/support/vuex-controller';
	import userService from "../service/user-service";
  import Auth from "@aws-amplify/auth";

	export default VueController("About", {
		state: [
			"cognito",
        "organization"
		],
		data() {
			return {
				blogs: [],
        displayName: "",
        saveDisplayNameInProgress: false
			}
		},
    mounted() {
      this.displayName = this.cognito.attributes.name + "";
    },
		methods: {
			grabFilterUser() {
				alert(JSON.stringify(this.cognito));
			},
      async updateDisplayName() {
        let self = this;
        self.saveDisplayNameInProgress = true;
        try {
          await userService.updateUserDisplayName(this.cognito, self.displayName);

          self.saveDisplayNameInProgress = false;
        } catch (error) {
          self.notification("error", "Unable to save user display name, please try again.");
          self.saveDisplayNameInProgress = false;
        }
        console.log("Name updated");
      }
		}
	});
</script>

<style lang="scss">

</style>
