import BaseService from "./support/base-service";
import Auth from '@aws-amplify/auth';

class UserService extends BaseService {
	getUserIdentity() {
		Auth.currentAuthenticatedUser().then((authenticatedUser, auError) => {
			//console.log("DUMP CURRENT_AUTHENTICATED_USER:");
			//console.log(JSON.stringify(authenticatedUser));
			Auth.currentCredentials().then((currentCredentials, upError) => {
				//console.log("DUMP CURRENT_CREDENTIALS:");
				//console.log(JSON.stringify(currentCredentials));
				Auth.currentUserInfo().then((currentUserInfo, cuError) => {
					//console.log("DUMP CURRENT_USER_INFO:");
					//console.log(JSON.stringify(currentUserInfo));
					Auth.currentSession().then((currentSession, csError) => {
						//console.log("DUMP CURRENT_SESSION:");
						//console.log(JSON.stringify(currentSession));
					});
				});
			});
		});
	}

	updateUserDisplayName(user, name) {
		return new Promise(async (resolve, reject) => {
			let self = this;
			try {
				let result = await Auth.updateUserAttributes(user, {
					name
				});

				let data = await Auth.currentAuthenticatedUser();

				if (data && data.signInUserSession && data.signInUserSession.isValid()) {
					self.store.state.cognito = data;
				}

				resolve(result);
			} catch (err) {
				reject(err);
			}
		});
	}
}

let service = new UserService();
export default service;
